@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
  @apply bg-gray-50;
}

.button-primary {
  @apply transition-all inline-block bg-orange-700 hover:bg-white border-2 hover:text-orange-700 border-orange-700 rounded-2xl px-6 py-2 font-semibold text-white;
}

.button-primary-blue {
  @apply transition-all inline-block bg-blue-400 hover:bg-white border-2 hover:text-blue-400 bg-blue-400 border-blue-400 rounded-2xl px-6 py-2 font-semibold text-white;
}

.button-primary-yellow {
  @apply transition-all inline-block bg-yellow-500 hover:bg-white border-2 hover:text-yellow-500 bg-yellow-500 border-yellow-500 rounded-2xl px-6 py-2 font-semibold text-white;
}

.button-primary-red {
  @apply transition-all inline-block bg-red-500 hover:bg-white border-2 hover:text-red-500 bg-red-500 border-red-500 rounded-2xl px-6 py-2 font-semibold text-white;
}

.button-primary-gray {
  @apply transition-all inline-block bg-slate-500 hover:bg-white border-2 hover:text-slate-500 bg-slate-500 border-slate-500 rounded-2xl px-6 py-2 font-semibold text-white;
}

.bg-blocks-header {
  background: center center no-repeat,
    radial-gradient(circle, rgb(57, 43, 95), rgb(50, 21, 72)) purple;
}

.rotate-20 {
  transform: rotate(20deg);
}

.start-shortly-wrapper {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.start-shortly-text {
  font-size: 2rem;
  margin-top: -25px;
}
.options {
  background: #f1ebdb;
}
.option {
  display: block;
  height: 25%;
  width: 100%;
  border-radius: 0;
  border: 0;
  background: #f1ebdb;
  color: black;
  font-weight: normal;
}
.option:first-child {
  margin-top: 10%;
  /*height: 40%;*/
}
.option:last-child {
}
.option:hover,
.option:focus {
  background: #fff;
}

/* Clock */
.clock {
  position: relative;
  z-index: 12;
  pointer-events: none;
  width: 300px;
  height: 300px;
  background-image: url(../src/assets/img/flip-clock-removebg.png);
  background-size: 100%;
  background-repeat: no-repeat;
  pointer-events: none;
  padding: 125px 31px 96px 62px;
}
.time {
  background: #f1ebdb;
  color: black;
  border-radius: 1em;
  box-shadow: 0 0 3px #f1ebdb;
  width: 100%;
  height: 81px;
  padding-top: 20px;
  text-align: center;
  transform: rotate3d(10.5, -3.7, 1, -27deg);
  white-space: nowrap;
}
.days {
  line-height: 20px;
  margin: -10px 0 0;
  font-size: 14px;
  text-align: center;
}
.cou {
  display: inline-block;
  font-size: 37px;
  line-height: 40px;
  font-weight: 500;
  font-family: monospace;
}

/* TV */
.tv {
  position: absolute;
  top: -42%;
  bottom: -42%;
  left: -15%;
  right: -42%;
  background-image: url(../src/assets/img/tv.png);
  background-size: 100%;
  background-repeat: no-repeat;
  pointer-events: none;
}

.embed-container {
  position: relative;
  padding-bottom: 90%;
  height: 0;
  max-width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}
.embed-container > .embed,
.embed-container > iframe,
.embed-container > object,
.embed-container > embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.dark {
  padding: 2em 0 5em;
  background-image: url(../src/assets/img/bg-blocks.svg),
    radial-gradient(circle, #392b5f, #321548);
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: white;
  min-height: 100vh;
  margin: 0;
  text-align: center;
}
.dark hr {
  background: white;
}
